.navcircle {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    background-color: rgb(191, 218, 72);
    border-radius: 50%; /* Ensures the element is a circle */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px; /* Adjust font size as needed */
    color: white; /* Color of the letter */
  }

  .navcircleinitial {
    font-size: medium;
  }